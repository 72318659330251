@use '../../../theme/colors';

.page404{
  margin-bottom: 50px;
  .page404header{
    position: relative;
    overflow: hidden;
    padding-bottom: 10px;
    .background{
      z-index: 1;
      background-color: colors.$background;
      position: absolute;
      width: 100%;
      top: -50%;
      height: 100%;
      mask-image: url('../../../assets/mask/filtersMask.svg');
      -webkit-mask-image: url('../../../assets/mask/filtersMask.svg');
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      mask-position: top;
      -webkit-mask-size: 100% 100%;
      mask-size: 100% 100%;
    }
    h1{
      margin: 0 0 110px 0;
      z-index: 990;
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 60px;
    }
  }
  .page404content{
    margin: auto;
    margin-top: -60px;
    width : 50vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    img{
      width : 50vw;
      max-width: 400px;
      margin-bottom: 30px;
    }
  } 
  
}