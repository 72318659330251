//This is a colors style file To change any color of the app

$red-1: #ED1C24 ;
$red-2: #EB4E62 ;
$green-1: #8DFF1C ;
$green-2: #BAFF76 ;
$yellow-1: #ECC652 ;
$yellow-2: #FFE082 ;
$text: #252525 ;
$background: #242424 ;
$grey-1 :#CFCFCF;
$grey-2 :#707070;
$grey-3 : #E8E8E8 ;
$white : #fff;

$red-gradient : linear-gradient(to bottom right, $red-2, $red-1);
$green-gradient : linear-gradient(to bottom right, $green-2, $green-1);
$yellow-gradient : linear-gradient(to bottom right, $yellow-2, $yellow-1);