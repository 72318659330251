@use './../../../../theme/colors';
@use './../../../../theme/fonts';
.profile{
  .line{
    background-color: colors.$grey-1;
    height: 2px;
    margin-top: 15px;
    margin-bottom: 23.3px;
  }
  .description{
    @include fonts.smallText;
    color: colors.$grey-2;
  }
  .settingsProfileItem{
    display: flex;
    align-items: center;
    @media screen and (max-width : 700px) {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .settingsProfileLabelName{
      display: flex;
      flex-direction: column;
      width: 40%;
      padding-right: 30px;
      .label{
        @include fonts.Text;
      }
    }
    .settingsProfilInputs{
      display: flex;
      justify-content: space-between;
      @media screen and (max-width : 700px) {
        width: 60%;
      }
      @media screen and (min-width : 700px) {
        width: 40%;
      }
      .permis{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .permis-expired{
          width: 300px;
          margin-left: 20px;
        }
      }
      .inputComponent{
        width: 100%;
        .select-date input{
          color: colors.$grey-2;
        }
        .date{
          >div{
            @media screen and (max-width : 700px) {
              border-radius: 18px;
            }
            
            @media screen and (min-width : 700px) {
              border-radius: 10px;
            }
            height: 36px;
            padding: 0 16.6px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            overflow: hidden;
          }
        }
      }
    }
    .NameInputs{
      .inputComponent{
        width: calc(((100% / 2) ) - 10px) !important;
      }
    }
  }
  .update-btn{
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    @media screen and (min-width:700px) {
      width: 80%;
    }
  }
}