@use '../../../theme/colors';
@use '../../../theme/fonts';

.contact-us {
  .header {
    position: relative;
    overflow: hidden;
    padding-bottom: 10px;

    .background {
      z-index: 1;
      background-color: colors.$background;
      position: absolute;
      width: 100%;
      top: -50%;
      height: 100%;
      mask-image: url('../../../assets/mask/filtersMask.svg');
      -webkit-mask-image: url('../../../assets/mask/filtersMask.svg');
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      mask-position: top;
      -webkit-mask-size: 100% 100%;
      mask-size: 100% 100%;
    }

    h1 {
      margin: 0 0 110px 0;
      z-index: 990;
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 60px;
    }
  }

  .content {
    margin: -60px auto 30px auto;
    max-width: 1100px;
    .inputComponent{
      margin-bottom: 20px;
    }
    .email {
      width: 50%;
    }

    .textarea{
      resize: none;
      height: 200px;
      padding: 16.6px;
    }
    .action{
      margin-top: 20px;
      margin-left: auto;
    }
  }
}