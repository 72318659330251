@use '../../../theme/colors';

.usersPage {
  height: 100%;

  .table {

    th,
    td {
      width: calc(100% / 6);

      .name {
        cursor: pointer;
        transition-duration: 0.3s;
      }

      .name:hover {
        color: colors.$red-1;
      }

    }
  }

}