@use '../../../theme/colors';

.calendarPage{

  .disabled{
    opacity: 0.5;
    pointer-events:none;
  }
  
  .fc {
    height: calc(100% - 160px);

    .fc-header-toolbar{
      
      display: flex;
      flex-direction: column;
      .fc-toolbar-chunk{
        display: flex;
        .fc-toolbar-title{
          color: colors.$grey-1;
        }
        .fc-button{
          background: colors.$red-gradient;
          border: none;
        }
        .fc-button:focus{
          box-shadow: none;
        }
        .fc-button-group{
          .fc-button{
            background: inherit;
            background-color: inherit;
            color: colors.$grey-1;
            border: none;
          }
        
        }
      }
    }
    
    .fc-scrollgrid{
      border-width: 1px;
    }
  
    .fc-scrollgrid-section > *{
      border-width: 0;
    }
    .fc-daygrid{
      box-shadow: 0px 0px 6px #00000030;
      border-radius: 10px;
    }
    table{
      border-radius: 10px;
      border-bottom: 1px solid colors.$grey-3;
      overflow: hidden;
  
      th{
        border: none;
        border-bottom: 2px solid colors.$grey-3;
        height: 46.6px;
      }
    }
  }
  
 
}