@use "../../theme/colors";

.auth {
  display: flex;
  align-items: stretch;
  min-height: var(--vh);

  .form-view {
    .form-container {
      min-height: var(--vh);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 30px;

      .logo-container {
        width: 100%;
        display: flex;
      }

      .form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .auth-btn {
          width: 100%;
        }

        .permis{
          display: flex;
          justify-content: space-between;
          width: 100%;
          .permis-expired{
            width: 300px;
            margin-left: 20px;
          }
        }

        .inputComponent {
          width: 100%;

          svg {
            cursor: pointer;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          .date{
            >div{
              @media screen and (max-width : 700px) {
                border-radius: 18px;
              }
              
              @media screen and (min-width : 700px) {
                border-radius: 10px;
              }
              height: 36px;
              padding: 0 16.6px;
              background-color: #fff;
              display: flex;
              align-items: center;
              justify-content: space-between;
              overflow: hidden;
            }
          }

          input[type=number] {
            -moz-appearance: textfield;
          }
        }

        .nameInput {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .inputComponent {
            width: calc(((100% / 2)) - 10px);
          }
        }

        .select-date {
          .date {
            width: 100%;
          }
        }

        .forget-password-link {
          color: colors.$red-1;
        }

        .separetor {
          color: colors.$grey-2;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          .line {
            height: 1px;
            background-color: colors.$grey-2;
            width: inherit;
          }

          p {
            margin: 0 10px;
          }
        }

      }

      .gap {
        height: 16.6px;
      }
    }
  }

  @media screen and (max-width : 700px) {
    .form-view {
      width: 100vw;

      .logo-container {
        justify-content: center;
        margin-bottom: 10px;
      }

      .form {
        width: 100%;
      }
    }

    .thumbnail {
      display: none;
    }
  }

  @media screen and (min-width : 700px) {
    .form-view {
      width: 50vw;

      .signUp {
        justify-content: flex-end;
      }

      .form {
        width: 60%;
      }
    }

    .thumbnail {
      min-height: var(--vh);
      width: 50vw;
      background-image: url("../../assets/images/slamaRentCarSignIn.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: right;
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      color: #fff;

      .slegon {
        width: 80%;

        p {
          text-align: center;
        }

        svg {
          width: 100%;
        }

      }

      .links {
        display: flex;

        .sociel-link {
          height: 48px;
          width: 48px;
          border: 2px solid #fff;
          color: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px;

          svg {
            height: 25px;
            width: 25px;
          }
        }
      }


    }

  }


  .forget-password {
    width: 100vw;

    .forget-password-content {
      position: relative;

      .background {
        z-index: 1;
        background-color: colors.$background;
        position: absolute;
        width: 100%;
        top: -50%;
        height: 100%;
        mask-image: url('../../assets/mask/filtersMask.svg');
        -webkit-mask-image: url('../../assets/mask/filtersMask.svg');
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
      }

      .newPassword {
        .inputComponent {
          margin-top: 30px;
        }
      }

      .forget-password-container {
        @media screen and (max-width : 700px) {
          width: 80vw;
        }

        @media screen and (min-width : 700px) {
          width: 50vw;
        }

        position: relative;
        z-index: 990;
        background-color: #fff;
        margin: auto;
        max-width: 500px;
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px solid colors.$grey-2;
        border-radius: 15px;
        padding: 30px;

        .inputComponent {
          width: 100%;
        }

        .reset-btn {
          margin-top: 30px;
        }

        .description {
          display: flex;
          align-items: center;

          svg {
            color: colors.$green-1;
            width: 30px;
            height: 30px;
            margin-left: 30px;
          }
        }

        .email-verified {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
      }

    }
  }
}