@use '../../../theme/colors';
@use '../../../theme/fonts' as fonts;

header{
  background-color: colors.$background;
  padding: 30px;
  position: relative;
  z-index: 990;
  .number .btn svg{
    margin-right: 6.66px;
    height: 18px;
    width: 18px;
  }
  .header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width:700px) {
      .logo-desctop{
        display: none;
      }
    }
    @media screen and (min-width:700px) {
      .logo-mobile{
        display: none;
      }
    }
    .nav{
      display: flex;
      align-items: center;
      a{
        margin-left: 10px;
      }
      .profile svg{
        height: 16px;
        width: 16px;
      }
      .nav-menu{
        color: #fff;
        @media screen and (max-width : 700px) {
          svg{
            cursor: pointer;
            margin-left: 15.33px;
            height: 25px;
            width: 25px;
          }
          a{
            display: none;
          }
        }
        @media screen and (min-width : 700px) {
          svg{
            display: none;
          }
          a{
            color: #fff;
            font-weight: bold;
            margin-left: 15.33px;
          }
        }
      }
    }
  }
  .cull-mobile{
    margin-top: 12px;
    display: flex;
    justify-content: center;
  }

}