//This is a base style file To change all the theme 

@use './fonts';
@use './colors';

html{
  height: -webkit-fill-available;
  scroll-behavior: smooth;
}
body{
  background-color: #fff;
  color: colors.$text;
  width: 100%;
  margin:0; 
  position:relative; 
  min-height: var(--vh);
  min-height: -webkit-fill-available;
  #root{
    min-height: var(--vh);
  }
  .logo{
    height: 44px;
    width: auto
  }
  p{
    @include fonts.Text;
  }
  svg{
    height: auto;
  }
  .PhoneInputCountryIcon{
    border-radius: 5px;
    overflow: hidden;
  }
  a,button{
    cursor: pointer;
    text-decoration: none
  }
  input,
  textarea {
    user-select: text;
    -moz-user-select: text;
  }  
  *{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    @include fonts.fontFamily;
  }
  
  ::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }
  
  ::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 7px;
    border-radius: 7px;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  
  ::-webkit-scrollbar-button {
    display: none;
    width: 0;
    height: 0;
  }
  
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  
  @media screen and (min-width : 700px) {
    .D700{
      display: none;
    }
    .W30{
      width: 30%;
    }
    .W50{
      width: 50%;
    }
    .W60{
      width: 60%;
    }
    .view{
      padding : 0 81px;
    }
  }
  @media screen and (max-width : 700px) {
    .D1900{
      display: none;      
    }
    .W30{
      width: 100%;
    }
    .W50{
      width: 100%;
    }
    .W60{
      width: 100%;
    }
    .view{
      padding : 0 33px;
    }
  }

  @media screen and (max-width : 1100px) {
    .D1100{
      display: none;
    }
  }

  @media screen and (max-width : 700px) {
    .forget-password::after,.Main::after {
      content: '';
      display: block;
      height: 900px;
    }
  }

  @media screen and (max-width : 1000px) and (min-width : 700px) {
    .forget-password::after,.Main::after {
      content: '';
      display: block;
      height: 1000px;
    }
  }
  
  @media screen and (min-width : 1000px) {
    .forget-password::after,.Main::after {
      content: '';
      display: block;
      height: 500px;
    }
  }
}
