@use '../../../theme/colors';

.carsMainPage {
  .carFilters {
    position: relative;
    overflow: hidden;
    padding-bottom: 10px;

    .background {
      z-index: 1;
      background-color: colors.$background;
      position: absolute;
      width: 100%;
      top: -50%;
      height: 100%;
      mask-image: url('../../../assets/mask/filtersMask.svg');
      -webkit-mask-image: url('../../../assets/mask/filtersMask.svg');
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 100% 100%;
      mask-size: 100% 100%;
    }

    .filters {
      z-index: 990;
      background-color: #fff;
      width: 80%;
      position: relative;
      margin: 25px auto;
      padding: 20px;
      border-radius: 25px;
      box-shadow: 0px 0px 25px #00000030;
      color: colors.$grey-2;

      .title {
        margin: auto;
        width: 100%;
        text-align: center;
      }

      .form {
        display: flex;
        flex-direction: column;

        .inputsFilter {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          .inputContainer {
            margin: 5px;
            display: flex;
            justify-content: center;

            @media screen and (max-width : 700px) {
              .selectInput {
                width: 100%;
              }
            }
          }

          @media screen and (max-width : 700px) {
            >div {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .carList {
    margin-top: -60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 33px;

    @media screen and (max-width:700px) {
      flex-direction: column;
    }

    .no-car,
    .carItem {
      margin-top: 50px;
    }

    .no-car {
      width: 100%;

      >h3 {
        text-align: center;
      }

      .recommendation {
        width: 80%;
        display: grid;
        align-items: center;
        grid-template-columns: min-content auto;
        .title{
          white-space: nowrap;
        }
        .line {
          height: 4px;
          width: 100%;
          background-color: colors.$background;
          position: relative;
          margin-left: 20px;

          &::before,
          &::after {
            content: '';
            position: absolute;
            background-color: colors.$text;
            width: 4px;
            height: 4px;
            border-radius: 50%;
          }

          &::before {
            left: -2px;
          }

          &::after {
            right: -2px;
          }
        }
      }
    }

  }
}