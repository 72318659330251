@use '../../../theme/colors';

.search {
  .title {
    display: flex;
    align-items: center;
  
    .reload {
      cursor: pointer;
      height: 24px;
      width: 24px;
      margin-left: 10px;
    }
  }
  
  .search-bar {
    background-color: colors.$grey-3;
    min-height: 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 10px 10px;
    transition-property: height;
    transition-duration: 1s;

    .search-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .search-bar-inputs {
        display: flex;
        align-items: center;

        .search-input {
          margin-top: 10px;
          min-width: 200px;
          margin-right: 10px;
        }

        .select {
          margin-top: 10px;
          margin-right: 10px;
        }

        .search-select {
          display: flex;
          align-items: center;

          @media screen and (max-width : 1200px) {
            display: none;
          }
        }

      }

      .add-btn {
        margin-top: 10px;
      }

      .add,
      .filter {
        margin-top: 10px;
        height: 36px;
        width: 36px;
        cursor: pointer;
      }

      .add {
        color: colors.$green-1;
      }
    }

    .mobile-filter {
      @media screen and (min-width : 1200px) {
        display: none;
      }

      @media only screen and (max-width :700px) {
        width: 100%;
      }

      @media only screen and (min-width :700px) and (max-width :1200px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .selectInput {
          margin-right: 10px;
          margin-left: 10px;
        }
      }

      animation: append-animate .3s linear;

      .selectInput {
        margin-top: 10px;
      }
    }

  }
}

@keyframes append-animate {
  from {
    transform: scaleY(0);
    opacity: 0;
  }

  to {
    transform: scaleY(1);
    opacity: 1;
  }
}