@use '../../../../theme/colors';
@use '../../../../theme/fonts';

.add-rent {
  overflow-y: auto;
  max-height: calc(var(--vh) * 0.8 - 40px);

  .selectInput,
  .inputComponent {
    margin-bottom: 20px;
  }

  .contrat {
    margin-top: 0;
  }

  .input {
    z-index: 999;
    margin: 10px 10px 0;
  }

  .search {
    position: relative;

    .add-client {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .selectInput {
        width: calc(100% - 56px);
      }

      .add-selected {
        color: colors.$grey-2;
      }

      .add {
        height: 36px;
        width: 36px;
        margin-bottom: 20px;
        margin-right: 10px;
        cursor: pointer;
        z-index: 999;
      }
    }

    .searchResult {
      animation: searchAnimation 1s;
      box-shadow: 0 0 6px #00000030;
      ;
      background-color: #fff;
      color: colors.$text;
      padding: 0 5px;
      padding-top: 66px;
      margin-bottom: 20px;
      border-radius: 10px;
      max-height: 50vh;
      overflow-y: auto;

      .user {
        padding: 10px;
        @include fonts.Text;
        cursor: pointer;
      }
    }
  }

  .check {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    .checkBox {
      color: colors.$text;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(50% - 10px);
      background-color: #fff;
      border-radius: 10px;
      padding: 15px;

      .checkBoxLabel {
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: auto 60%;
        grid-template-rows: 50% 50%;

        .icon {
          grid-row: 1 / span 2;

          svg {
            width: 100%
          }
        }

        span {
          @include fonts.smallText;
        }
      }
    }
  }

  .resum {
    padding: 0 10px;

    li {
      &.price-item {
        list-style: none;
        margin-left: 10px;
        @include fonts.Text;
      }

      &.remarq {
        @include fonts.smallText;
      }
    }
  }

  .selectInput {
    padding: 0 10px;
  }

  .action {
    display: flex;
    margin: 10px 10px 0 10px;
    align-items: flex-end;
    justify-content: flex-end;
    border-top: 2px solid #fff;
    padding-top: 10px;

    .inputComponent {
      margin: 0;
    }

    .btn {
      margin-left: 10px;
    }
  }

}

@keyframes searchAnimation {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}