.addModel{
  .addModelTitle{
    >h1{
      margin-top: 0;
    }
  }
  .back-btn{
    height: 30px;
    width: 30px;
    cursor: pointer;
  }

  .form{
    #select-brand{
      margin: 20px 0;
    }
    .imageSelect{
      margin: 10px 0;
    }
    .add-btn{
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }
}