@use '../../../../theme/colors';

.newOrOlfEmployer {
  .white-btn {
    background-color: #fff;
    color: colors.$text;
  }
  
  .gap {
    height: 20px;
    width: 100%;
  }

}
