@use "../../../theme/colors";
@use "../../../theme/fonts";

.selectInput{
  display: flex;
  flex-direction: column;
  .select{
    cursor: pointer;
    color: colors.$text;
    background-color: #fff;
    border: 1px solid colors.$grey-2;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
    min-width: 50px;
    padding-left: 16.6px;
    padding-right: 50px;
    height: 36px;
    @media screen and (max-width : 700px) {
      border-radius: 18px;
    }
    @media screen and (min-width : 700px) {
      border-radius: 10px;
    }
    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
  }
  .error-style{
    border: 2px solid colors.$red-2;
  }
  .input-error{
    color: colors.$red-1;
    text-align: center;
    margin-top: 8px;
    @include fonts.Text;
  }
}