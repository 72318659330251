@use '../../../theme/colors';
.historicPage{
  height: 100%;
  .table{
    .car-name{
      display: flex;
      align-items: center;
      min-width: 200px !important;
      width: 100% !important;
      height: 62px;
      .carImg{
        max-height: 70%;
        margin: 0 10px;
      }
      p{
        margin: 0;
      }
    }
  }
  
}
