@use '../../../../theme/colors';
@use '../../../../theme/fonts';

.emailUpdate{
  display: flex;
  flex-direction: column;
  
  .noVerifyEmail{
    @include fonts.smallText;
    margin-top: 10px;
    span{
      color : colors.$red-1;
    }
  }
}