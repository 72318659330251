@use "../../../../theme/colors";

.points {
  .add-point{
    cursor: pointer;
    width: fit-content;
    display: flex;
    align-items: center;
    color : colors.$green-1;
    .btn{
      margin-right: 20px;
    }
  }
  .points-list {
    padding: 20px;
    .point{
      margin-bottom: 10px;
      width: 100% ;
      max-width: 300px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .action{
        display: flex;
        .delete,.edit{
          cursor: pointer;
        }
  
        .delete{
          margin-left: 20px;
        }
      }
    }
  }
}