@use '../../../theme/colors';

.aboutUsPage {
  position: relative;

  .background {
    z-index: 1;
    background-color: colors.$background;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -5px;
    mask-image: url('../../../assets/mask/aboutUsMask.svg');
    -webkit-mask-image: url('../../../assets/mask/aboutUsMask.svg');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 110% 100%;
    mask-size: 110% 100%;
    mask-position: center;
    -webkit-mask-position: center;
  }

  .content {
    position: relative;
    z-index: 990;
    padding-bottom: 31px;
    color: #fff;

    .title {
      text-align: center;

      h2 {
        margin: 0;
        font-size: 60px;
      }
    }

    .aboutUs-container {
      background-color: #fff;

      @media screen and (max-width : 700px) {
        width: 100%;
      }

      @media screen and (min-width : 700px) {
        width: 80%;
      }

      margin: 50px auto;
      padding: 20px;
      border-radius: 25px;
      box-shadow: 0px 0px 6px #00000030;
      color: colors.$grey-2;

      .descriptionContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;

        .description {
          padding-left: 20px;

          p {
            margin-top: 5px;
          }
        }

        .map {
          border: none;
          border-radius: 10px;
          @media screen and (max-width : 700px) {
            width: 100%;
          }
        }
      }

    }
  }
}