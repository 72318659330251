@use '../../../theme/colors';
@use '../../../theme/fonts';

.emailSend {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  text-align: center;
  height: 100%;

  .logo {
    margin-bottom: 50px;
  }

  .email-send-container {
    box-shadow: 0px 0px 6px #00000030;
    @media screen and (max-width : 700px) {
      width: 80vw;
    }
    
    @media screen and (min-width : 700px) {
      width: 60vw;
    }
    padding: 50px 50px 30px 50px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 300px;

    .title {
      margin-top: 200px;
      margin-bottom: 0;
      @include fonts.BigText;
    }

    .email{
      margin: 30px 0;
      @include fonts.Text;
    }

    .change-email-container{
      margin: 30px 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .input{
        margin-top: 5px;
      }
      .btn{
        min-width: 0;
        margin-top: 5px;
        margin-left: 10px;
      }
      @media screen and (max-width : 700px) {
        .btn{
          width: auto;
        }
      }

    }
    
    .change-email,.receive-again{
      color: colors.$red-2;
      cursor: pointer;
    }

    .notSend{
      margin: 50px 0 0 0;
      @include fonts.smallText;
    }

    .letter-image {
      position: absolute;
      top: 100px;
      left: 50%;
      width: 200px;
      height: 200px;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    .animated-mail {
      position: absolute;
      height: 150px;
      width: 200px;
      -webkit-transition: .4s;
      -moz-transition: .4s;
      transition: .4s;

      .body {
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 100px 200px;
        border-color: transparent transparent colors.$red-1 transparent;
        z-index: 2;
      }

      .top-fold {
        position: absolute;
        top: 50px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 50px 100px 0 100px;
        -webkit-transform-origin: 50% 0%;
        -webkit-transition: transform .4s .4s, z-index .2s .4s;
        -moz-transform-origin: 50% 0%;
        -moz-transition: transform .4s .4s, z-index .2s .4s;
        transform-origin: 50% 0%;
        transition: transform .4s .4s, z-index .2s .4s;
        border-color: colors.$red-2 transparent transparent transparent;
        z-index: 2;
      }

      .back-fold {
        position: absolute;
        bottom: 0;
        width: 200px;
        height: 100px;
        background: colors.$red-2;
        z-index: 0;
      }

      .left-fold {
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 50px 0 50px 100px;
        border-color: transparent transparent transparent colors.$red-1;
        z-index: 2;
      }

      .letter {
        left: 20px;
        bottom: 0px;
        position: absolute;
        width: 160px;
        height: 60px;
        background: white;
        z-index: 1;
        overflow: hidden;
        -webkit-transition: .4s .2s;
        -moz-transition: .4s .2s;
        transition: .4s .2s;

        .letter-border {
          height: 10px;
          width: 100%;
          background: repeating-linear-gradient(-45deg,
              colors.$red-1,
              colors.$red-1 8px,
              transparent 8px,
              transparent 18px);
        }

        .letter-title {
          margin-top: 10px;
          margin-left: 5px;
          height: 10px;
          width: 40%;
          background: colors.$red-1;
        }

        .letter-context {
          margin-top: 10px;
          margin-left: 5px;
          height: 10px;
          width: 20%;
          background: colors.$red-1;
        }

        .letter-stamp {
          margin-top: 30px;
          margin-left: 120px;
          border-radius: 100%;
          height: 30px;
          width: 30px;
          background: colors.$red-1;
          opacity: 0.3;
        }
      }
    }

    .shadow {
      position: absolute;
      top: 200px;
      left: 50%;
      width: 400px;
      height: 30px;
      transition: .4s;
      transform: translateX(-50%);
      -webkit-transition: .4s;
      -webkit-transform: translateX(-50%);
      -moz-transition: .4s;
      -moz-transform: translateX(-50%);

      border-radius: 100%;
      background: radial-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0));
    }

    .letter-image:hover {
      .animated-mail {
        transform: translateY(50px);
        -webkit-transform: translateY(50px);
        -moz-transform: translateY(50px);
      }

      .animated-mail .top-fold {
        transition: transform .4s, z-index .2s;
        transform: rotateX(180deg);
        -webkit-transition: transform .4s, z-index .2s;
        -webkit-transform: rotateX(180deg);
        -moz-transition: transform .4s, z-index .2s;
        -moz-transform: rotateX(180deg);
        z-index: 0;
      }

      .animated-mail .letter {
        height: 180px;
      }

      .shadow {
        width: 250px;
      }
    }
  }
}