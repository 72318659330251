@use '../../../../../theme/colors';

.updateCar{
  @media screen and (min-width : 700px) {
    min-width: 340px;
  }
  max-height: calc(80vh - 40px);
  height: 100%;
  overflow: auto;
  .addCarTitle{
    >h1{
      margin-top: 0;
    }
    >p{
      margin: 0;
    }
  }
}