@use "../../../../../theme/colors";

.add-client{
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .auth-btn {
      width: 100%;
    }

    .permis{
      display: flex;
      justify-content: space-between;
      width: 100%;
      .permis-expired{
        width: 300px;
        margin-left: 20px;
      }
    }

    .inputComponent {
      width: 100%;

      svg {
        cursor: pointer;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .date{
        >div{
          @media screen and (max-width : 700px) {
            border-radius: 18px;
          }
          
          @media screen and (min-width : 700px) {
            border-radius: 10px;
          }
          height: 36px;
          padding: 0 16.6px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow: hidden;
        }
      }

      input[type=number] {
        -moz-appearance: textfield;
      }
    }

    .nameInput {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .inputComponent {
        width: calc(((100% / 2)) - 10px);
      }
    }

    .select-date {
      .date {
        width: 100%;
      }
    }

    .forget-password-link {
      color: colors.$red-1;
    }

    .separetor {
      color: colors.$grey-2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .line {
        height: 1px;
        background-color: colors.$grey-2;
        width: inherit;
      }

      p {
        margin: 0 10px;
      }
    }
    .gap {
      height: 16.6px;
    }
  }
}