@use "../../../../theme/colors";

.contact {
  .emails {
    width: 100%;
    .already-seen {
      font-weight: bold;
    }


    .email:hover {
      box-shadow: 0px 0px 6px #00000050;
    }

    .email {
      cursor: pointer;
      width: 100%;
      padding: 15px;
      border-bottom: 2px solid colors.$grey-3;
      display: grid;

      @media screen and (max-width : 700px) {
        grid-template-columns: auto 40px;
      }

      @media screen and (min-width : 700px) {
        grid-template-columns: 100px min-content auto 40px;
        column-gap: 20px;
        align-items: center;
      }

      .action {
        display: flex;
        justify-content: center;
        @media screen and (max-width : 700px) {
          grid-row: 1/4;
          grid-column: 2; 
        }
        

        .delete {
          cursor: pointer;
        }
      }
    }
  }
}