@use '../../../theme/colors';
@use '../../../theme/fonts';


.dashboardNav{
  position: fixed;
  z-index: 990;
  background-color: colors.$background;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (max-width:700px) {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    top: calc(100% - 60px);
    border-radius: 50px 50px 0 0 ;
    width: 100% ;
    height: calc(100% - 40px);
    padding-top: 20px;
    .logoContainer{
      @media screen and (max-width : 700px) {
        display: none;
      }
      
      @media screen and (min-width : 700px) {
        display: flex;
        justify-content: center;
      }
    }
  }
  @media screen and (min-width:700px) {
    width: 270px ;
    height: 100vh;
  }
  .lineContaine{
    width: 100%;
    @media screen and (max-width : 700px) {
      margin-bottom: 50px;
    }
    
    @media screen and (min-width : 700px) {
      margin-bottom: 20px;
    }
    .line{
      width: 50%;
      height: 1px;
      background-color: #fff;
      margin: auto;
    }
  }
  .navItemList{
    @media screen and (min-width : 700px) {
      margin-top: 50px;
    }
    color: #fff;
    @include fonts.BigText;
  }
  .dashboardNav-bottom{
    .settings{
      color: #fff;
    }
    .line{
      background-color: #fff;
      width: inherit;
      height: 2px;
      margin-top: 26.6px;
      margin-bottom: 13.3px;
    }
    .account{
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 42.6px;
      .account-desplay{
        display: flex;
        height: 100%;
        max-width: calc(100% - 16px);
        .account-img{
          background: colors.$red-gradient ;
          width: 42.6px;
          height: 42.6px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
        .account-name{
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 10px;
          max-width: calc(100% - 42.6px);
          @include fonts.Text; 
          .email{
            font-size: 70%; 
            overflow-wrap: break-word;
          }
        }
      }
      .logout{
        cursor: pointer;
      }
    }
  }
}

