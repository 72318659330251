//This is a components style file To change style of buttons,inputs...
@use "./colors" as colors;
@use "./fonts" as fonts;

label {
  margin-bottom: 7.33px;
}

input {
  height: 100%;
  width: 100%;
  border: none;
}

input:focus,
select:focus {
  outline: none;
}

.checkbox>svg {
  color: colors.$red-2;
}


.inputComponent {
  display: flex;
  flex-direction: column;

  .input {
    height: 36px;
    padding: 0 16.6px;
    background-color: #fff;
    border: 1px solid colors.$grey-2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    a {
      display: flex;
      align-items: center;
    }
  }

  svg {
    color: colors.$text;
  }

  .error-style {
    border: 2px solid colors.$red-2;
  }

  .input-error {
    color: colors.$red-1;
    text-align: center;
    margin-top: 8px;
    @include Text;
  }

  .input-message {
    color: colors.$green-1;
    text-align: center;
    margin-top: 8px;
    @include Text;
  }
}


@media screen and(max-width : 700px) {

  .inputComponent {
    width: 100%;

    .input {
      border-radius: 18px;
    }
  }
}

@media screen and(min-width : 700px) {
  .inputComponent {
    .input {
      border-radius: 10px;
    }
  }
}

.pagination-container {
  background-color: #fff;
  position: sticky;
  bottom: 0;
  border-top: 1px solid colors.$grey-3;

  .pagination {
    display: flex;
    list-style: none;
    width: fit-content;
    padding: 5px;
    margin: 0;
    position: sticky;
    left: 0;

    >li {
      margin: 0 5px;
      padding: 4px;

      &.selected {
        border-radius: 2px;
        background-color: colors.$red-2;
        color: #fff;

        >a {
          cursor: default;
        }
      }

      &.disabled {
        color: colors.$grey-1;

        >a {
          cursor: default;
        }
      }
    }
  }
}