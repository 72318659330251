@use '../../theme/colors';

.dashboard {
  display: flex;

  .dashboard-content {
    position: absolute;
    min-height: 100%;

    @media screen and(max-width : 700px) {
      width: 100%;
      padding: 30px;
      padding-bottom: 70px;
    }

    @media screen and(min-width : 700px) {
      right: 0;
      width: calc(100% - 270px);
      padding: 30px;
    }

    .btn-menu-mobile{
      @media screen and (max-width : 700px) {
        display: flex;
      }
      
      @media screen and (min-width : 700px) {
        display: none;
      }
      justify-content: flex-end;
      margin-bottom: 10px;
      .btn-open-menu{
        height: 25px;
        width: 25px;
        cursor: pointer;
      }
    }

    .line {
      background-color: colors.$grey-3;
      height: 1px;
      margin: 10px 0;
    }

    h1 {
      margin-top: 0;
    }

    .dashboard-page-content {
      overflow: auto;
      box-shadow: 0px 0px 6px #00000030;
      border-radius: 10px;
      height: calc(100vh - 230px);
      position: relative;
      display: grid;
      align-content: space-between;

      .table {
        width: 100%;
        border-collapse: collapse;
        border-radius: 10px 0;
        
        .action {
          display: flex;
          width: 100px;
          min-width: 0px;
          justify-content: center;
          align-items: center;
          height: 62px;

          .action-btn {
            margin: 0 5px;

            svg {
              width: 80%;
              height: 80%;
              color: #fff;
            }
          }
        }

        .action2 {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 62px;

          .action-btn {
            margin: 0 5px;

            svg {
              width: 80%;
              height: 80%;
              color: #fff;
            }
          }
        }

        .sticky-col {
          position: -webkit-sticky;
          position: sticky;
          left: 0px;
          background-color: #fff;
          border-right: none;
        }

        th.sticky-col{
          height: 46px ;
          border-radius: 10px 0 0 0;
        }

        th.sticky-col:after {
          height: 46px ;
        }

        .sticky-col:after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          background-color: colors.$grey-3;
          width: var(--widthSticky);
          height: 100%;
          box-shadow: 2px 0px 5px #00000030;
        }

        .table-row {
          height: 62px;
        }

        .table-header {
          height: 46px ;
        }

        tr {
          border-bottom: 1px solid colors.$grey-3;
          height: 100%;
          th,
          td {
            text-align: left;
            padding: 0 20px;
            min-width: 160px;
            border-right: 1px solid colors.$grey-3;
          }
        }

        .green {
          color: colors.$green-1;
        }

        .red {
          color: colors.$red-1;
        }

        .yellow {
          color: colors.$yellow-1;
        }

        .black {
          color: colors.$text;
        }
      }
    }
  }
}