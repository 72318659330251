.employersPage {
  height: 100%;

  .table {

    th,
    td {
      width: calc(100% / 5);
    }
  }
}