//This is a font style file To change All the fonts of the app

@font-face {
  font-family: 'signature';
  src: url('../assets/fonts/Signature.otf?') format('otf'),
        url('../assets/fonts/Signature.ttf') format('ttf');
  }

@mixin Text {
  @media (min-width: 700px) { font-size: 14px; }
  @media (max-width: 699px) { font-size: 12px; }
}

@mixin BigText {
  @media (min-width: 700px) { font-size: 16px; }
  @media (max-width: 699px) { font-size: 14px; }
}

@mixin smallText {
  @media (min-width: 700px) { font-size: 12px; }
  @media (max-width: 699px) { font-size: 10px; }
}

@mixin fontFamily {
  font-family: 'roboto';
}