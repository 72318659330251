@use '../../../../../theme/colors';
@use '../../../../../theme/fonts';

.carFields {

  .upDateCar-btn,
  .addCar-btn,
  .imageCar,
  .label {
    margin-top: 20px;
  }

  .colorCar {
    display: grid;

    .colorCarInput {
      margin-top: 10px;

      input {
        margin: 0 10px;
        height: 20px;
        width: 20px;
      }

    }
  }

  .label {
    @include fonts.BigText;
    margin-bottom: 7.33px;
    margin-top: 20px;
  }

  .prices {
    display: grid;
    grid-column-gap: 20px;

    @media screen and (max-width : 700px) {
      grid-template-columns: repeat(2, auto);
      grid-row-gap: 10px;
    }

    @media screen and (min-width : 700px) {
      grid-template-columns: repeat(4, auto);

      .input {
        width: 100px;
      }
    }

    .label {
      margin-top: 0;
      @include fonts.smallText;
    }

  }

  .radioSelections {
    display: flex;

    .radioSelectContainer {
      margin-top: 20px;
      width: 50%;

      .radioSelect {
        display: flex;
        margin-top: 10px;

        .radioList {
          display: flex;
          align-items: center;

          .radio {
            height: 15px;
            width: auto;
            margin: 3px;
          }

          >label {
            margin: 0;
          }
        }
      }
    }
  }

  .addBrandModel {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .selectInput {
      width: calc(100% - 56px);
    }

    .add {
      height: 36px;
      width: 36px;
      cursor: pointer;
    }
  }

  .error {
    color: red;
    margin-top: 20px;
  }

  .action-btn {
    margin-right: 10px;
  }

  .upDateCar-btn,
  .addCar-btn {
    display: flex;
    justify-content: flex-end;
  }
}