@use '../../../theme/colors';

.availablity{
  .search-bar {
    background-color: colors.$grey-3;
    min-height: 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    transition-property: height;
    transition-duration: 1s;

    .search-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .inputContainer{
        display: flex;
        align-items: center;
        >svg{
          margin: 0 10px;
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}