@use "../../theme/colors";
@use "../../theme/fonts";

.primary-btn{
  background: colors.$red-gradient;
  color: #fff;
  border: none;
}

.secondary-btn{
  background: transparent;
  color: colors.$red-1;
  border: 1px solid colors.$red-1;
}

.success-btn{
  background: colors.$green-gradient;
  color: #fff;
  border: none;
}

.edit-btn{
  background: colors.$yellow-gradient;
  color: #fff;
  border: none;
}

.white-btn{
  background: #fff;
  color: colors.$text;
  border: none;
}

.btn{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include fonts.Text;
}

.small-btn{
  height: 24px;
  min-width: 175px;
}
.normal-btn{
  height: 36px;
  padding: 0px 15px;
}
.small-btn-rounded{
  padding: 0;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

@media screen and(max-width : 700px) {
  .small-btn{
    border-radius: 12px;
    width: 100%;
  }
  .normal-btn{
    border-radius: 18px;
    width: 100%;
  }
  .normal-btn-rounded{
    height: 24px;
    width: 24px;
    border-radius: 50%;
    padding: 0;
  }
  .inputComponent{
    width: 100%;
    .input{
      border-radius: 18px;
    }
  }
}
@media screen and(min-width : 700px) {
  .small-btn{
    border-radius: 12px;
    min-width: 175px;
  }
  .normal-btn{
    border-radius: 10px;
    min-width: 175px;
  }
  .normal-btn-rounded{
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }
  .inputComponent{
    .input{
      border-radius: 10px;
    }
  }
}