@use '../../../../../theme/colors';

.addCar{
  @media screen and (min-width : 700px) {
    min-width: 340px;
  }
  max-height: calc(80vh - 40px);
  height: 100%;
  overflow: auto;
  .addCarTitle{
    >h1{
      margin-top: 0;
    }
    >p{
      margin: 0;
    }
  }
  .colorCar{
    display: grid;
    .colorCarInput{
      margin-top: 10px;
      input{
        margin: 0 10px;
        height: 20px;
        width: 20px;
      }
      
    }
  }
  .radioSelections{
    display: flex;
    .radioSelectContainer{
      margin-top: 20px;
      width: 50%;
      .radioSelect{
        display: flex;
        margin-top: 10px;
        .radioList{
          display: flex;
          align-items: center;
          .radio{
            height: 15px;
            width: auto;
          }
        }
      }
    }
  }
  .imageDisplayContainer{
    margin: 10px 0;
    display: flex;
    justify-content: center;
    .imageDisplay{
      max-width: 300px;
      width: 100%;
    }
  }
  
  .addBrandModel{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .selectInput{
      width: calc(100% - 56px);
    }
    .add{
      height: 36px;
      width: 36px;
      cursor: pointer;
    }
  }
  
  .addCar-btn{
    display: flex;
    justify-content: flex-end;
  }
}