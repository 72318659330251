@use "../../../../theme/colors";

.email-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;

  .email-sent {
    .back{
      cursor: pointer;
    }
  }

  .header {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .email-respance {
    .line{
      height: 4px;
      background-color: colors.$background;
      width: 50%;
      margin: 20px auto;
      position: relative;
      &::before,
      &::after {
        content: '';
        position: absolute;
        background-color: colors.$text;
        width: 4px;
        height: 4px;
        border-radius: 50%;
      }

      &::before {
        left: -2px;
      }

      &::after {
        right: -2px;
      }
    }
    .textarea {
      resize: none;
      height: 100px;
      padding: 16.6px;
    }

    .action {
      margin-top: 20px;
      margin-left: auto;
    }
  }
}