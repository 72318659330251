.newAccount{
  .form{
    .nameInput{
      display: flex;
      justify-content: space-between;
      width: 100%;
      .inputComponent{
        width: calc(((100% / 2) ) - 10px);
      }
    }
    .gap{
      height: 10px;
    }
    .auth-btn{
      margin-left: auto;
    }
  }
}