@use '../../theme/colors' ;

.popup-screen{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 999;
  display: flex;
  align-items: center;
  .popup{
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    padding: 20px;
    max-width: 82%;
    max-height: 80vh;
    height: fit-content;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 38px;
    margin: auto;
    .popup-content{
      color: #fff;
      .mobile-menu{
        display: grid;
        grid-template-columns: repeat(2,calc(50% - 10px));
        column-gap: 20px;
        align-items: center;
        width: 60vw;
        > a {
          
          color: #fff;
          border-radius: 25px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          .icon{
            border-radius: 50%;
            padding: 10px;
            background:linear-gradient(135deg, rgba(255,255, 2555, 0.5), rgba(255,255, 2555, 0.5));
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        :last-child{
          margin-bottom: 0;
        }
        :first-child{
          margin-top: 0;
        }
      }
    }
  }
}