@use '../../../theme/colors';

.imageCar{
  .carImage-btn{
    background-color: #fff;
    color: colors.$text;
    cursor: pointer;
    padding: 0px 15px;
    margin-right: 10px;
    @media screen and (max-width : 700px) {
      border-radius: 10px;
    }
    @media screen and (min-width : 700px) {
      border-radius: 5px;
    }
  }
  
  .imageCarSelect{
    margin-top: 10px;
    display: flex;
    >input[type=file]::file-selector-button {
      display: none;
    }
  }
  .error{
    color: red;
    margin-top: 10px;
  }
}