.addBrand{
  max-height: calc(var(--vh) * 0.8 - 40px);
  overflow-y: auto;
  .addBrandTitle{
    >h1{
      margin-top: 0;
    }
  }
  .back-btn{
    height: 30px;
    width: 30px;
    cursor: pointer;
  }

  .form{
    .imageSelect{
      margin: 10px 0;
    }
    .add-btn{
      display: flex;
      justify-content: flex-end;
    }
  }
}