@use '../../../theme/colors';

.footer {
  width: 100%;
  position: absolute;
  bottom: 0;


  .background {
    z-index: 1;
    top: 0;
    background-color: colors.$background;
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    -webkit-mask-image: url("../../../assets/mask/footerMask.svg");
    mask-image: url("../../../assets/mask/footerMask.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 110% 100%;
    mask-size: 110% 100%;
    mask-position: center;
    -webkit-mask-position: center;

    .lines {
      width: 100%;
      height: 200%;
      object-fit: cover;

      @media screen and (min-width : 1000px) {
        margin-left: -50%;
      }

    }
  }

  .footer-content {
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;

    .footer-icons {
      z-index: 99;
      border-radius: 30px;
      box-shadow: 0px 0px 25px #00000030;
      padding: 30px;
      box-sizing: border-box;
      background-color: #fff;
      width: 70%;
      display: grid;
      justify-content: space-around;
      justify-items: center;

      @media screen and (min-width : 1000px) {
        grid-template-columns: repeat(3, 25%);
      }

      @media screen and (max-width : 1000px) {
        grid-template-columns: repeat(2, 30%);

        #lastIcon {
          grid-area: 2 / 1 / span 2 /span 2;
        }
      }

      .footer-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        max-width: 100px;
        color: colors.$text;

        svg {
          height: auto;
          width: 70%;
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    .footer-content-2 {
      z-index: 99;
      color: #fff;
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin-top: 30px;

      @media screen and (max-width : 1000px) {
        flex-direction: column;
        align-items: center;

        .footer-content-column {
          width: 100%;
          text-align: center;
        }

        .footer-content-social {
          width: 100%;
        }
      }

      @media screen and (min-width:1000px) {
        .footer-content-column {
          width: 25%;
        }

        .footer-content-social {
          width: 5%;
        }
      }

      .menu {
        nav {
          ul {
            padding: 0;

            a {
              color: #fff;

              li {
                text-align: center;
                list-style-type: none;
                margin-bottom: 30px;
              }
            }

          }
        }

      }

      .social {
        display: flex;

        @media screen and (min-width:700px) {
          flex-direction: column;
        }

        .sociel-link {
          color: #fff;
          height: 48px;
          width: 48px;
          border: 2px solid #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px;

          svg {
            height: 25px;
            width: 25px;
          }
        }
      }

      .contact {
        h2 {
          margin-top: 0;
        }

        ul {
          padding: 0;

          li {
            list-style-type: none;
          }
        }
      }
    }

    .copyright {
      width: 80%;
      z-index: 99;
      color: #fff;
      text-align: center;

      .line {
        background-color: #fff;
        height: 1px;
      }

      a {
        color: #fff;
      }
    }
  }

}