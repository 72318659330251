@use '../../../../../theme/colors';
@use '../../../../../theme/fonts';

.oldAccount{

  .input{
    z-index: 999;
    margin: 10px 10px 0;
  }
  .searchResult{
    animation: searchAnimation 1s ;
    background-color: #fff;
    color: colors.$text;
    padding: 0 5px ;
    padding-top: 46px;
    margin-top: -46px;
    border-radius: 10px;
    max-height: 50vh;
    overflow-y: auto;
    .user{
      padding: 10px;
      @include fonts.Text;
      cursor: pointer;
    }
  }
}

@keyframes searchAnimation {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}