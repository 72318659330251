@use '../../theme/colors';

.carItem {
  padding: 0 10px;

  @media screen and (max-width:700px) {
    width: 100%;
  }

  @media screen and (min-width:700px) {
    width: 25%;
    max-width: 300px;
    min-width: 300px;
  }

  .carImage {
    padding: 0 10%;
    width: 100%;
  }

  .carItem-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -15%;
    background-color: colors.$grey-3;
    border-radius: 25px;
    padding: 25px;

    .title {
      margin-top: 15%;
    }

    .carDescription {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      width: 100%;

      svg {
        color: colors.$red-2;
        margin-right: 10%;
      }
    }

    .carPrice {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 1em;

      .TND {
        font-size: 10px;
      }
    }

    .carReserve {
      margin-bottom: -43px;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .reserve-btn {
        @media screen and (max-width : 700px) {
          width: 50%;
        }

        min-width: 0;

        svg {
          margin-left: 10px;
          height: 50%;
          width: fit-content;
        }
      }
    }
  }
}