
.updateRent{
  .tableContainer{
    max-width: 80vw;
    overflow-x: auto;
    .table{
      border-bottom: 2px solid #fff;
      padding-bottom: 10px;
      width: 100%;
      td,th{
        padding: 0 10px;
        text-align: left;
        white-space: nowrap;
      }
    }
  }
  .action{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 10px;
    @media screen and (max-width : 700px) {
      flex-direction: column;
    }

    .input{
      margin-right: 10px;
    }
    h3{
      margin: 10px 0;
      display: flex;
      .amount-paied{
        margin: 0 10px;
      }
    }
    .actions-btn{
      display: flex;
      .btn{
        margin-left: 10px;
      }

      
      @media screen and (max-width : 700px) {
        .action-btn{
          height: 36px;
          width: 36px;
        }
        .submit-btn{
          width: auto;
        }
        margin-top: 10px;
      }
    }
  }
}