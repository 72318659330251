@use '../../../../theme/colors';

.settingsNavItem{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  font-size: 12px;
  border-radius: 4.6px;
  padding: 0 8px;
  cursor: pointer;
}

.settingsNavItemSelected{
  background-color: colors.$red-2;
  color: #fff;
}