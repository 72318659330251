@use '../../../../theme/colors';

.userHistory {
  width: 100%;

  .tableContainer {
    overflow-x: auto;
    position: relative;
    background-color: #fff;
    border-radius: 20px;
    height: 50vh;

    .table {
      width: 100%;
      border-collapse: collapse;
      color: colors.$grey-2;

      .sticky-col {
        position: -webkit-sticky;
        position: sticky;
        left: 0px;
        background-color: #fff;

      }

      .car-name {
        border-bottom: 1px solid colors.$grey-3;
      }

      .table-row {
        height: 62px;
      }

      .table-header {
        height: 46.6px;
        border-bottom: 1px solid colors.$grey-3;
      }

      tr {
        border-bottom: 1px solid colors.$grey-3;

        th,
        td {
          text-align: left;
          padding: 0 20px;
          min-width: 160px;
          border-right: 1px solid colors.$grey-3;
        }
      }

      .green {
        color: colors.$green-1;
      }

      .red {
        color: colors.$red-1;
      }

      .yellow {
        color: colors.$yellow-1;
      }

      .black {
        color: colors.$text;
      }

    }
  }
}