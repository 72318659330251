@use '../../../theme/colors';

.home {
  .home-header {
    min-height: calc(100vh - 108px);
    position: relative;

    .home-header-background {
      background-color: colors.$background;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .lines {
        @media screen and (max-width : 700px) {
          margin-bottom: 50%;
        }

        @media screen and (min-width : 700px) {
          margin-left: 50%;
        }

        transform: rotate(90deg);

        height: 200%;
        object-fit: cover;

      }
    }

    .home-header-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-bottom: 33px;
      padding-top: 33px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      color: #fff;

      .home-header-top {
        z-index: 990;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width : 1100px) {
          flex-direction: column;
        }

        .form {
          max-width: 500px;

          @media screen and (max-width : 1100px) {
            width: 100%;
          }

          @media screen and (min-width : 1100px) {
            width: 30%;
          }

          svg {
            width: 100%;
          }

          form {
            .search-btn {
              @media screen and (min-width : 700px) {
                display: flex;
                justify-content: flex-end;
              }

              .devis {
                margin-top: 20px;
              }
            }
          }
        }

        .carSlide {
          @media screen and (max-width : 1100px) {
            margin-top: 30px;
            width: 100%;
          }

          @media screen and (min-width : 1100px) {
            width: 60%;
          }

          .mySwiper {
            -webkit-mask:linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 20% , rgba(0,0,0,1) 80%, rgba(0,0,0,0) 100%);
            mask:linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 20% , rgba(0,0,0,1) 80%, rgba(0,0,0,0) 100%);
            padding: 0 10%;
            .swiper-pagination-bullet-active {
              background: #fff;
            }

            .swiper-button-prev{
              margin-left: 10%;
            }

            .swiper-button-next{
              margin-right: 10%;
            }

            .swiper-button-prev,
            .swiper-button-next {
              color: #fff;
              top: calc(100% - 44px);

              @media screen and (max-width : 700px) {
                display: none;
              }
            }

            .carImage {
              >img {
                width: 100%;
              }
            }
          }
        }
      }

      .home-header-bot {
        @media screen and (max-width : 700px) {
          margin-top: 30px;
        }

        display: flex;
        justify-content: flex-end;
        z-index: 990;

        .container {
          position: relative;
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;

          .mouse {
            @media screen and (min-width : 700px) {
              width: 2.708vw;
              height: 4.219vw;
            }

            @media screen and (max-width : 700px) {
              width: 8.5vw;
              height: 13.3vw;
            }

            max-height: 54px;
            max-width: 34.6px;
            border: 3px solid #fff;
            border-radius: 60px;
            position: relative;
            overflow: hidden;

            &::before {
              content: '';
              width: 12px;
              height: 12px;
              position: absolute;
              top: 10px;
              left: 50%;
              transform: translateX(-50%);
              background-color: #fff;
              border-radius: 50%;
              opacity: 1;
              animation: wheel 2s infinite;
              -webkit-animation: wheel 2s infinite;
            }
          }

          .links {
            position: absolute;
            right: 0;
            display: flex;
            flex-direction: column;

            .sociel-link {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .bon-plan {
    padding-top: 33px;
    padding-bottom: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      @media screen and (max-width:700px) {
        max-width: 80%;
      }

      @media screen and (min-width:700px) {
        max-width: 50%;
      }

      text-align: center;
      margin: 10px 0;
    }

    .line {
      @media screen and (max-width:700px) {
        width: 30%;
      }

      @media screen and (min-width:700px) {
        width: 10%;
      }

      background-color: colors.$text;
      height: 4px;
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        background-color: colors.$text;
        width: 4px;
        height: 4px;
        border-radius: 50%;
      }

      &::before {
        left: -2px;
      }

      &::after {
        right: -2px;
      }
    }

    .carList {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;
      width: 100%;

      .carItem {
        margin-top: 50px;
      }

      @media screen and (max-width:700px) {
        flex-direction: column;
      }
    }
  }

  .showMore {
    @media screen and (min-width : 700px) {
      width: 80%;
    }

    margin: auto;
    padding-top: 33px;
    padding-bottom: 33px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    .line {
      position: relative;
      display: flex;
      background-color: colors.$text;
      height: 4px;

      @media screen and (min-width : 700px) {
        justify-content: flex-end;
        &::before{
          content: '';
          position: absolute;
          background-color: colors.$text;
          width: 4px;
          height: 4px;
          border-radius: 50%;
        }
      }

      @media screen and (max-width : 700px) {
        justify-content: center;
        &::before,&::after{
          content: '';
          position: absolute;
          background-color: colors.$text;
          width: 4px;
          height: 4px;
          border-radius: 50%;
        }
      }


      button {
        margin-top: -18px;
        padding: 0 10vw;
      }

      &::before {  
        left: -2px;
      }

      &::after {
        right: -2px;
      }
    }

  }

  .why-us {
    padding-top: 33px;
    padding-bottom: 33px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    .title {
      @media screen and (min-width : 1000px) {
        width: 50%;
      }

      h2 {
        width: 60%;
        text-align: center;
        margin: auto;
      }
    }

    .why-us-content {
      display: flex;
      align-items: center;
      margin-top: 40px;

      @media screen and (max-width : 1000px) {
        flex-direction: column-reverse;
      }

      .slama-offers {
        @media screen and (min-width : 1000px) {
          width: 50%;
        }

        @media screen and (max-width : 1000px) {
          margin-top: 30px;
          width: 100%;
        }

        .slama-description {
          background: colors.$red-gradient;
          padding: 25px;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          border-radius: 18px;
          color: #fff;

          h3 {
            margin-top: 0;
          }
        }

        .offers-list {
          display: grid;

          @media screen and (min-width : 1000px) {
            grid-template-columns: repeat(4, 20%);
            margin-top: 30px;
          }

          @media screen and (max-width : 1000px) {
            grid-template-columns: repeat(2, 40%);
            margin-top: 30px;
            justify-items: center;
          }

          justify-content: space-between;

          .offer {
            max-width: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: colors.$text;
            text-align: center;

            svg {
              width: 60%;
              height: fit-content;
            }
          }
        }
      }

      .golf-image {
        @media screen and (min-width : 700px) {
          width: 50%;
        }

        @media screen and (max-width : 700px) {
          width: 100%;
        }
      }
    }

  }
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 60px;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 60px;
  }
}


