@use '../../../theme/colors';
@use '../../../theme/fonts';

.carDescription {
  width: 100%;

  .carDescriptionHeader {
    display: grid;
    margin-top: -100px;

    @media screen and (max-width : 700px) {
      grid-template-columns: 100%;
    }

    @media screen and (min-width : 700px) {
      grid-template-columns: 50% 50%;
      grid-template-rows: 100px auto;
      column-gap: 41.33px;
    }

    .carImage {
      @media screen and (max-width : 700px) {
        grid-row: 1;
        margin: auto;
      }

      @media screen and (min-width : 700px) {
        grid-column: 2;
        grid-row: 1 / span 2;

      }

      margin: auto;
      max-height: 200px;
      max-width: 80%;
    }

    .carTechniq {
      @media screen and (max-width : 700px) {
        grid-row: 3;
      }

      @media screen and (min-width : 700px) {
        grid-column: 1;
        grid-row: 2 / span 2;
      }

      .equipments {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        row-gap: 5px;

        .equipmentItem {
          display: flex;
          align-items: center;

          .icon {
            width: 20px;
            color: colors.$red-1;
            margin-right: 10px;
          }
        }
      }
    }

    .carName {
      display: flex;
      align-items: center;
      justify-content: center;

      .brandLogo {
        width: 40px;
        margin-right: 10px;
      }

      @media screen and (max-width : 700px) {
        grid-row: 2;
        margin: 0;
      }
    }
  }

  .carDescription-mobile {
    margin-top: 20px;
  }

  .carDescription-2 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    @media screen and (max-width : 700px) {
      justify-content: center;
    }

    @media screen and (min-width : 700px) {
      justify-content: flex-end;
    }

    div {
      display: flex;
      align-items: center;
      white-space: nowrap;

      @media screen and (max-width : 700px) {
        margin: 0 10px 20px 10px;
      }

      @media screen and (min-width : 700px) {
        margin-left: 66.66px;
      }

      svg {
        color: colors.$red-2;
        margin-right: 10%;
        width: 25.3px;
      }
    }

  }

  .formContainer {
    padding: 10px;

    @media screen and (min-width : 700px) {
      margin-top: 20px;
    }

    @media screen and (max-width : 700px) {
      margin-top: 10px;
      overflow: auto;
      max-height: 50vh;
    }

    .form {
      display: grid;

      .total {
        font-weight: bold;
        font-size: medium;
      }

      .inputField {
        background-color: #fff;
        border-radius: 10px;
        padding: 15px;

      }

      .btn:disabled {
        cursor: auto;
      }

      @media screen and (min-width : 700px) {
        margin-top: 18px;
        max-height: 33vh;
        overflow: auto;
        align-items: end;
        grid-template-columns: 37% 20% auto;
      }

      @media screen and (min-width : 700px) and (max-width : 1100px) {
        column-gap: 15px;

        .container-btn {
          margin-top: 10px;
          grid-column: span 3;
          display: grid;
          align-items: end;
          grid-template-columns: 37% 20% auto;
          column-gap: 15px;

          .total {
            margin: auto 0;
          }

          .reserve-btn {
            grid-column: 3;
          }
        }

        #startDateReservation {
          grid-row: 2;
          grid-column: 1/3;
        }

        #endDateReservation {
          grid-row: 3;
          grid-column: 1/3;
        }

        #baby {
          grid-row: 2;
          grid-column: 3;
        }

        #secondDriver {
          grid-row: 3;
          grid-column: 3;
        }

        .resum {
          grid-row: 4;
          grid-column: 1/4;
          margin-top: 10px;
        }

        #point {
          grid-row: 1;
          grid-column: 1/4;
        }
      }

      @media screen and (min-width : 1100px) {
        column-gap: 41.33px;

        #point {
          grid-column: 1/3;
        }

        .resum {
          grid-column: 3;
          grid-row: 1 / 3;
        }
      }

      @media screen and (max-width : 700px) {

        grid-template-columns: 100%;
        grid-template-rows: auto;
        row-gap: 10px;
        align-items: center;

        .resum {
          grid-row: 6;
        }

        #point {
          grid-row: 1;
        }

        #baby {
          grid-row: 4;
        }

        #secondDriver {
          grid-row: 5;
        }
      }

      .checkBox {
        color: colors.$text;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .checkBoxLabel {
          width: 100%;
          display: grid;
          align-items: center;
          grid-template-columns: auto 60%;
          grid-template-rows: 50% 50%;

          .icon {
            grid-row: 1 / span 2;

            svg {
              width: 100%
            }
          }

          span {
            @include fonts.smallText;
          }
        }
      }

      .resum {
        color: colors.$text;

        ul {
          padding: 0;
          margin: 0;

          li {
            list-style: none;

            &.price-item {
              margin-left: 10px;
              @include fonts.Text;
            }
          }

          .remarq {
            @include fonts.smallText;
          }
        }

        .TND {
          @include fonts.smallText;
        }
      }

      .reserve-btn {
        width: 100%;
        min-width: 0;
      }

    }
  }

}