@use '../../../theme/colors';

.cars {
  .nav {
    display: flex;
    margin-left: 30.6px;
    margin-bottom: 20px;
    color: colors.$grey-2;
  }
}

.dashboard .dashboard-content .cars .dashboard-page-content{
  height: calc(var(--vh) - 270px);
}

.manage-model,
.manage-brand {
  .table {

    th,
    td {
      width: calc(100% / 3);
    }
  }
}