@use '../../../../theme/colors';

.navItem{
  padding: 13.3px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 36px;
  border-radius: 10px;
  cursor: pointer;
  svg{
    margin-right: 10px;
  }
}

.navItems{
  color: #fff;
}
.navItemselected{
  background-color: #fff;
  color: colors.$background;
}