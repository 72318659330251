.verify-popup{
  .action{
    display: flex;
    justify-content: flex-end;
    .btn{
      min-width: auto;
    }
    .cancel-btn{
        margin-right: 20px
    }
  }
}