@use '../../../../theme/colors';

.carsPage {
  height: calc(var(--vh) - 100px);

  .table {

    th,
    td {
      width: calc(100% / 5);

      &.action2 {
        height: 100px !important;
      }
    }

    td.prices {
      padding: 10px 20px !important;

      .TND {
        font-size: 10px;
      }
    }
  }
}

.imageDisplayContainer {
  margin: 10px 0;
  display: flex;
  justify-content: center;

  .imageDisplay {
    max-width: 300px;
    width: 100%;
  }
}