@import "./theme/theme.scss";

.App {
  text-align: center;
}

@font-face {
  font-family: 'signature';
  src: url('./assets/fonts/Signature.otf?') format('opentype'),
      url('./assets/fonts/Signature.ttf') format('ttf');
  }


