.banPage{
  .banList{
    padding: 20px;
    .userBanned{
      display: flex;
      align-items: center;
      .removeIcon{
        cursor: pointer;
        height: 24px;
        width: 24px;
      }
    }
  }
}